<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <h3>Legal & Surveying Requests</h3>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <select class="form-control" v-model="status" @change="fetch">
            <option value="pending">Pending</option>
            <option value="hidden">Hidden</option>
            <option value="declined">Declined</option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="! loading" class="card">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="4" v-if="requests.length == 0">There is no data to display.</td>
            </tr>
            <tr v-for="(row, i) in requests" :key="`request-${i}`">
              <td>{{ row.name }}</td>
              <td>{{ row.email }}</td>
              <td>{{ row.phone_number }}</td>
              <td>
                <router-link :to="{ name: 'admin.legal-and-surveying.show', params: { id: row.id }}">
                  Details
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12">
            <pagination v-model="pagination.page" :records="pagination.records" @paginate="paginate" :perPage="10" />
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: true,
      requests: [],
      status: 'pending',
      pagination: { page: 1, records: 1 }
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/admin/legal-surveying?status=${this.status}`).then(response => {
        this.requests = response.data.requests
        this.loading = false
      })
    }
  }
}
</script>